import React, { useEffect, useState } from 'react';
import './users-edit.scss';
import { Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import UsersForm from '../form';
import http from '../../../rest';
import { defaultUser } from '../constants/vars';

function EditingUser({
  match: {
    params: { id },
  },
  history,
  location,
}) {
  const primaryUser = location.state && location.state.primaryUser;
  const [user, setUser] = useState(defaultUser);
  const [photo, setPhoto] = useState(null);
  const [sign, setSign] = useState(null);
  useEffect(() => {
    let photoId;
    let signId;
    http.api
      .get(`/users/${id}/request`)
      .then(user => {
        setUser(Object.assign({}, defaultUser, user));
        photoId = user.photoId;
        signId = user.signId;
        const list = [
          (photoId &&
            http.api.get(`/file-storage/download/${photoId}`, {
              headers: {
                Accept: 'application/octet-stream',
                'Content-Type': 'application/octet-stream',
              },
              responseType: 'blob',
            })) ||
            null,

          (signId &&
            http.api.get(`/file-storage/download/${signId}`, {
              headers: {
                Accept: 'application/octet-stream',
                'Content-Type': 'application/octet-stream',
              },
              responseType: 'blob',
            })) ||
            null,
        ];
        return Promise.all(list);
      })
      .then(([photo, sign]) => {
        setPhoto(photo);
        setSign(sign);
      })
      .catch(console.error);
  }, [id]);

  const onSubmit = (user, photo, sign) => {
    const fd = new FormData();
    fd.set('user', JSON.stringify(user));
    if (photo) {
      fd.set('photo', photo);
    }
    if (sign) {
      fd.set('sign', sign, `${user.lastName}_${user.firstName}_sign.png`);
    }
    return http.api.put('/users', fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials: true,
    });
  };

  const goBack = e => {
    e.preventDefault();
    if (primaryUser && primaryUser.phone) {
      history.push({ pathname: `/users/${primaryUser.phone}` });
    } else {
      history.push({ pathname: '/users' });
    }
  };

  // todo проверить, что в user есть personalApproverIds

  return (
    <div className="users-edit">
      <div className="users-edit__header">
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/users">
            Участники
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>Редактирование</Breadcrumb.Section>
        </Breadcrumb>
      </div>
      <div className="users-edit__form">
        <UsersForm
          onSubmit={onSubmit}
          data={user}
          photo={photo}
          sign={sign}
          isPrimaryUser={user.primary}
          onGoBack={goBack}
        />
      </div>
    </div>
  );
}

export default EditingUser;
