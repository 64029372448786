import React from 'react';
import PropTypes from 'prop-types';

export function BlueStampSvg({ lastName = '', firstName = '', middleName = '' }) {
  return (
    <svg
      id="sign_svg"
      xmlns="http://www.w3.org/2000/svg"
      width="240"
      height="80"
      viewBox="0 0 240 80">
      <g fill="white" fillRule="evenodd">
        <path stroke="#2C87EA" strokeWidth="3" d="M1 1h238v78H1z" />
        <text
          fill="#016FE6"
          fillOpacity=".832"
          fontFamily="Arial"
          fontSize="15"
          fontStyle="condensed">
          <tspan y="50" x="50%" dominantBaseline="middle" textAnchor="middle">
            {`${firstName} ${middleName}`}
          </tspan>
        </text>
        <text
          fill="#016FE6"
          fillOpacity=".832"
          fontFamily="Arial"
          fontSize="18"
          fontStyle="condensed"
          fontWeight="bold"
          letterSpacing="1">
          <tspan y="30" x="50%" dominantBaseline="middle" textAnchor="middle">
            {lastName.toUpperCase()}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

BlueStampSvg.propTypes = {
  lastName: PropTypes.string,
  firstName: PropTypes.string,
  middleName: PropTypes.string,
};
