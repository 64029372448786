import React, { useEffect, useState, useCallback } from 'react';
import { Input, Transition, Icon, Message, Form } from 'semantic-ui-react';

import isEmpty from 'lodash/fp/isEmpty';

import formatUserName from '../../../utils/string_utils';
import './decision.scss';

import DocView from '../doc_view';
import InWork from './in_work';
import statusesMap from '../../../constants/decision_business_statuses_map';
import {
  REQUESTED,
  FINAL_REQUESTED,
  UNDEFINED,
} from '../../../constants/decision_business_statuses';
import http from '../../../rest';
import Attach from '../attach';
import { debounce } from '../../../utils/form';
import { DECISION_COMMENT_MAX_LENGTH } from '../constants/documents';

import DocumentPreviewList from '../../../components/document_preview_list';
import { DateTimeByStatus } from './datetime_by_status';

const MESSAGE_TIMEOUT = 5000;

const identity = ({ id }) => id;
const multipleCommentRoles = ['ROLE_INITIATOR', 'ROLE_FINAL_APPROVER'];

function Decision({ decision }) {
  const {
    user,
    businessStatus,
    comment,
    comment_en,
    documents: docs,
    removedDocuments: removedDocs,
  } = decision;

  const [editedComment, setEditedComment] = useState(comment);
  const [editedCommentEn, setEditedCommentEn] = useState(comment_en);
  const [documents, setDocuments] = useState(docs);
  const [removedDocuments, setRemovedDocuments] = useState(removedDocs);
  const [formError, setFormError] = useState('');
  const [sending, setSending] = useState(false);
  const [removedVisible, setRemovedVisible] = useState(false);

  const haveRemovedDocuments = !isEmpty(removedDocuments);

  const collapseText = removedVisible
    ? 'Скрыть удалённые файлы'
    : `Удалённые файлы (${removedDocuments.length})`;

  const toggleRemoved = () => setRemovedVisible(visible => !visible);

  const deleteDoc = docId => {
    setRemovedDocuments([...removedDocuments, ...documents.filter(({ id }) => docId === id)]);
    setDocuments([...documents.filter(({ id }) => docId !== id)]);

    updateDocuments({ documentsIds: [], removedDocumentsIds: [docId] });
  };

  const addDocs = newDocuments => {
    setDocuments([...documents, ...newDocuments]);

    updateDocuments({
      documentsIds: [...newDocuments.map(identity)],
      removedDocumentsIds: [],
    });
  };

  const updateDocuments = ({ documentsIds, removedDocumentsIds }) => {
    http.rpc
      .post('/updateDecisionDocumentsAdmin', {
        decisionId: decision.id,
        documents: documentsIds,
        removedDocuments: removedDocumentsIds,
      })
      .then(resp => {
        if (!resp.status || resp.data.code === 500) {
          setFormError(resp.data.message);
        }
      })
      .catch(err => {
        setFormError(err.message);
      });
  };

  const updateComment = ({ ru, en }) => {
    setFormError('');
    setSending(true);
    return http.rpc
      .post('/updateDecisionCommentAdmin', {
        decisionId: decision.id,
        comment: ru,
        comment_en: en,
      })
      .then(resp => {
        if (!resp.status || resp.data.code === 500) {
          setFormError(resp.data.message);
        }
        setSending(false);
      })
      .catch(err => {
        setFormError(err.message);
        setSending(false);
      });
  };

  useEffect(() => {
    const id = setTimeout(() => {
      setFormError('');
    }, MESSAGE_TIMEOUT);
    return () => clearTimeout(id);
  }, [formError]);

  const debouncePackageUpdate = useCallback(debounce(updateComment, 500), [decision]);

  const onCommentChange = (evt, { value }) => {
    setEditedComment(value);
    debouncePackageUpdate({ ru: value, en: editedCommentEn });
  };

  const onCommentChangeEn = (evt, { value }) => {
    setEditedCommentEn(value);
    debouncePackageUpdate({ ru: editedComment, en: value });
  };

  const needMultipleLanguageComments = multipleCommentRoles.includes(user.role);

  return (
    <div className="decision">
      <div className="decision__header">
        <div>{formatUserName(user)}</div>

        <DateTimeByStatus decision={decision} />

        {decision.daysSpent > 0 && (
          <InWork daysSpent={decision.daysSpent} status={businessStatus || UNDEFINED} />
        )}
        <div>{statusesMap[businessStatus]}</div>
      </div>

      {![FINAL_REQUESTED, REQUESTED].includes(businessStatus) && (
        <Form>
          <Form.Field>
            <Input
              fluid
              label="ru"
              labelPosition="right"
              placeholder="Комментарий на русском языке..."
              value={editedComment}
              onChange={onCommentChange}
              loading={sending}
              maxLength={DECISION_COMMENT_MAX_LENGTH}
            />
          </Form.Field>
          {needMultipleLanguageComments && (
            <Form.Field>
              <Input
                fluid
                label="eng"
                labelPosition="right"
                placeholder="Комментарий на английском языке..."
                value={editedCommentEn}
                onChange={onCommentChangeEn}
                loading={sending}
                maxLength={DECISION_COMMENT_MAX_LENGTH}
              />
            </Form.Field>
          )}
        </Form>
      )}

      <DocView documents={documents} deleteDoc={deleteDoc} />
      <Attach onUpload={addDocs} />

      {removedVisible && (
        <div className="coordination__files_removed">
          <DocumentPreviewList docList={removedDocuments} forceViewed={true} />
        </div>
      )}

      {haveRemovedDocuments && (
        <div className="coordination__files_more --executor" onClick={toggleRemoved}>
          {collapseText}
        </div>
      )}
      <Transition visible={Boolean(formError)} animation="scale" duration={500}>
        <Message attached="bottom" error>
          <Icon name="times" />
          {formError}
        </Message>
      </Transition>
    </div>
  );
}

export default Decision;
