import {
  APPROVED,
  APPROVING,
  DELETED,
  DRAFT,
  EXECUTED,
  EXECUTING,
  FINAL_APPROVED,
  FINAL_REJECTED,
  MY_APPROVING,
  MY_RESPONSE,
  REJECTED,
  REWORKING,
  UNDEFINED,
} from './document_package_business_status';

const statusesMap = {
  [APPROVED]: 'На утверждении',
  [APPROVING]: 'На согласовании',
  [DELETED]: 'Удален',
  [DRAFT]: 'Черновик',
  [EXECUTED]: 'Завершено',
  [EXECUTING]: 'В работе',
  [FINAL_APPROVED]: 'Утверждено',
  [FINAL_REJECTED]: 'Не утверждено',
  [MY_APPROVING]: 'Ожидает моего решения',
  [MY_RESPONSE]: 'Ожидает моего ответа',
  [REJECTED]: 'Отклонено',
  [REWORKING]: 'На доработке',
  [UNDEFINED]: 'Не определен',
};

export default statusesMap;
