import React, { Fragment, useState } from 'react';
import './doc-view.scss';
import { getFileURL } from '../../../utils/get_document_url';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';

function DocView({ documents, label, deleteDoc }) {
  const [deleteIsVisible, setDeleteIsVisible] = useState([]);
  const setDelete = id => {
    const isVisible = deleteIsVisible.includes(id);
    setDeleteIsVisible(
      isVisible ? deleteIsVisible.filter(i => i !== id) : deleteIsVisible.concat(id)
    );
  };
  if (documents.length === 0) {
    return (label && <div>{label}</div>) || null;
  }

  return (
    <Fragment>
      {label && <div>{label}</div>}
      {documents.map(doc => {
        const isVisible = deleteIsVisible.includes(doc.id);
        return (
          <div key={`key-doc-view-${label}-${doc.id}`}>
            <div className="doc-view">
              <div className="doc-view__filename">
                {doc.originalFileId && (
                  <a href={getFileURL(doc.originalFileId)} download>
                    {doc.fileName}
                  </a>
                )}
              </div>
              <div
                className={'doc-view__spoiler' + (isVisible ? ' --open' : '')}
                onClick={() => setDelete(doc.id)}
              />
            </div>
            {isVisible && (
              <Button
                size="mini"
                color="red"
                onClick={() => {
                  setDelete(doc.id);
                  deleteDoc(doc.id);
                }}>
                Удалить
              </Button>
            )}
          </div>
        );
      })}
    </Fragment>
  );
}

DocView.propTypes = {
  label: PropTypes.string,
  documents: PropTypes.array.isRequired,
  deleteDoc: PropTypes.func.isRequired,
};
export default DocView;
