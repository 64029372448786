export const getUrl = () => window.location.origin;
export const getApiUrl = () => `${getUrl()}/api`;

export const getApprovementUrl = (fileId = '#') =>
  encodeURI(`${getApiUrl()}/approvalsheet/${fileId}`);

export const getFileURL = fileId => encodeURI(`${getApiUrl()}/file-storage/download/${fileId}`);

export const getWebClientUrl = () => {
  const isLocalhost = window.location.hostname === 'localhost';

  return isLocalhost ? 'http://localhost:3001' : `${window.location.origin.replace('admin.', '')}`;
};
