import React, { useState, useEffect } from 'react';

import { Divider, Table } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import http from '../../rest';
import formatDate from '../../utils/date_utils';
import { getSavedSort, saveSort, SORT_DESC } from '../../utils/localStorage';
import formatUserName from '../../utils/string_utils';
import statusesMap from '../../constants/document_package_business_status_map';
import { DELETED } from '../../constants/document_package_business_status';

import './file-manager.scss';

const tableThs = [
  {
    name: 'Имя',
    key: 'title',
  },
  {
    name: 'Статус',
    key: 'businessStatusLabel',
  },
  {
    name: 'Дата',
    key: 'date',
  },
  {
    name: 'Создатель',
    key: 'initiator',
  },
  {
    name: 'Исполнитель',
    key: 'executor',
  },
];

function docsFilterMap(docs) {
  return docs
    .filter(({ status }) => status !== DELETED)
    .map(doc => ({
      id: doc.id,
      initiator: formatUserName(doc.createdBy),
      businessStatus: doc.businessStatus,
      businessStatusLabel: statusesMap[doc.businessStatus],
      date: doc.createdDate,
      executor: doc.executors[0] ? formatUserName(doc.executors[0]) : '',
      type: doc.type,
      title: doc.title || (doc.type === 'TASK' ? 'Поручение' : 'Пакет документов'),
    }));
}

function FileManager() {
  const [sortDirection, setSortDirection] = useState(SORT_DESC);
  const [sortKey, setSortKey] = useState('');
  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const [field, sort] = getSavedSort(`${FileManager.name}:sort`);

    http.api.get('/documents').then(docs => {
      const preparedDocs = docsFilterMap(docs);

      setDocuments(field ? sortDocuments(preparedDocs, field, sort) : preparedDocs);
    });
    // eslint-disable-next-line
  }, []);

  const sortDocuments = (docs, field, sortAsc = !sortDirection) => {
    setSortKey(field);
    setSortDirection(sortAsc);

    // сохраним сортировку в localStorage
    saveSort(`${FileManager.name}:sort`, field, sortAsc);

    docs.sort(function(a, b) {
      const num = a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0;
      return (sortAsc && num) || -num;
    });

    return docs;
  };

  const handleSortDocuments = key => {
    const docs = sortDocuments(documents, key);
    setDocuments(docs);
  };

  return (
    <div className="file-manager">
      <div className="file-manager__header">
        <div>Файловый менеджер</div>
      </div>
      <Divider />
      <Table celled selectable fixed>
        <Table.Header>
          <Table.Row>
            {tableThs.map(({ key, name }) => {
              const cn = ['file-manager__sort'];
              if (sortKey === key) {
                cn.push('--active');
              }
              if (sortDirection === SORT_DESC) {
                cn.push('--desc');
              }
              return (
                <Table.HeaderCell key={key}>
                  <div className={cn.join(' ')} onClick={() => handleSortDocuments(key)}>
                    {name}
                  </div>
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {documents.map(doc => (
            <Table.Row key={doc.id}>
              <Table.Cell width={4}>
                <Link to={`/file-manager/${doc.id}`}>{doc.title}</Link>
              </Table.Cell>
              <Table.Cell width={4}>
                <Link to={`/file-manager/${doc.id}`}>{doc.businessStatusLabel}</Link>
              </Table.Cell>
              <Table.Cell>{formatDate(doc.date)}</Table.Cell>
              <Table.Cell>{doc.initiator}</Table.Cell>
              <Table.Cell>{doc.executor}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

export default FileManager;
