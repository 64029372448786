import React from 'react';
import PropTypes from 'prop-types';

import { Button, Card, Segment, Label } from 'semantic-ui-react';

import { defaultUser } from '../../constants/vars';
import { REMOVED, LOCKED } from '../../constants/states';

export function SecretaryCard(props) {
  const { user, lang, onEdit, onRemove } = props;
  return (
    <Card centered={true}>
      <Card.Content>
        {[REMOVED, LOCKED].includes(user.state) && (
          <Label color={user.state === REMOVED ? 'red' : 'grey'} ribbon="right">
            {user.state}
          </Label>
        )}
        {lang === 'ENG' ? (
          <Card.Header>
            {user.lastName_en} {user.firstName_en}
          </Card.Header>
        ) : (
          <Card.Header>
            {user.lastName} {user.firstName}
          </Card.Header>
        )}
        <Card.Meta>{user.email}</Card.Meta>
        <Card.Description>{user.phone}</Card.Description>
      </Card.Content>
      {user.state !== REMOVED && (
        <Card.Content extra>
          <div className="ui two buttons">
            <Button basic primary onClick={() => onEdit(user)}>
              {lang === 'RUS' ? 'Редактировать' : 'Edit'}
            </Button>
            <Button basic color="red" onClick={() => onRemove(user)}>
              {lang === 'RUS' ? 'Удалить' : 'Remove'}
            </Button>
          </div>
        </Card.Content>
      )}
    </Card>
  );
}

SecretaryCard.defaultProps = {
  user: defaultUser,
  lang: 'RUS',
};

SecretaryCard.propTypes = {
  user: PropTypes.object,
  lang: PropTypes.oneOf(['RUS', 'ENG']),
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
};

export function AddNewSecretaryCard(props) {
  const { onAdd } = props;
  return (
    <Segment basic>
      <Button size="massive" circular icon="add user" onClick={onAdd} />
    </Segment>
  );
}

AddNewSecretaryCard.propTypes = {
  onAdd: PropTypes.func,
};
