import React, { useState } from 'react';
import { Link, Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Users from '../pages/users';
import './app.scss';
import { Icon, Menu } from 'semantic-ui-react';
import NewUser from '../pages/users/new';
import EditingUser from '../pages/users/edit';

import logo from '../images/logo.svg';
import { authCheckState, logoutUser } from '../store/user/actions';
import { connect } from 'react-redux';
import FileManager from '../pages/file_manager';
import FileManagerView from '../pages/file_manager/view';
import Auth from '../pages/auth';
import Logout from '../pages/logout';
import { ROLE_ADMIN, ROLE_ROOT } from '../constants/roles';

const pages = [
  { url: '/users', name: 'Участники' },
  { url: '/file-manager', name: 'Файловый менеджер' },
];

function App({ location, user, authCheckState, logoutUser }) {
  const { authenticated, role } = user;
  let [prevAuthenticated, setPrevAuthenticated] = useState(undefined);

  if (typeof authenticated !== 'boolean') {
    authCheckState();
    return null;
  }
  if (authenticated !== prevAuthenticated) {
    setPrevAuthenticated(authenticated);
  }

  const goToMain = () => {
    window.location = window.location.origin.replace(/admin\./, '');
  };

  const isAdmin = [ROLE_ROOT, ROLE_ADMIN].includes(role);
  const isLogoutPage = location.pathname === '/logout';

  return (
    <div className="layout">
      {isAdmin && !isLogoutPage && (
        <Menu className="layout__menu" size="mini">
          <Menu.Item onClick={goToMain}>
            <img src={logo} alt="logo" />
          </Menu.Item>
          {pages.map(page => {
            return (
              <Menu.Item
                name={page.url}
                active={location.pathname.includes(page.url)}
                key={page.url}
                as={Link}
                to={page.url}>
                {page.name}
              </Menu.Item>
            );
          })}

          <Menu.Menu position="right">
            <Menu.Item className="layout__menu__logout">
              <Icon name="log out" onClick={logoutUser} size="big" color="red" />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      )}
      <div className="content">
        <Switch>
          {isAdmin && <Route exact path="/file-manager/:id" component={FileManagerView} />}
          {isAdmin && <Route exact path="/file-manager" component={FileManager} />}
          {isAdmin && <Route exact path="/users/new/:accountId?" component={NewUser} />}
          {isAdmin && <Route exact path="/users/:id/:accountId?" component={EditingUser} />}
          {isAdmin && <Route exact path="/users" component={Users} />}
          {!isAdmin && <Route exact path="/auth" component={Auth} />}
          {/* used for force logout if smth goes wrong  */}
          <Route exact path="/logout" component={Logout} />
          <Redirect to={authenticated ? '/users' : '/auth'} />
        </Switch>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = { authCheckState, logoutUser };
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
