import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { sendLogoutReq, logoutAction } from '../../store/user/actions';
import {
  LOGGING_OUT_MESSAGE,
  LOGGED_OUT_MESSAGE,
  REDIRECT_MESSAGE,
  REDIRECT_TIME,
  REDIRECT_TIME_DECREASE_INTERVAL,
  RETRY_REQUEST_INTERVAL,
} from '../../constants/logout';

import './logout.scss';

function Logout({ logoutAction, history }) {
  const [reqMessage, setReqMessage] = useState(LOGGING_OUT_MESSAGE);
  const [reqSended, setReqSended] = useState(false);
  const [showRedirectMessage, setShowRedirectMessage] = useState(false);
  const [timeToRedirect, setTimeToRedirect] = useState(REDIRECT_TIME);

  const redirect = useCallback(() => {
    history.push({ pathname: '/auth' });
  }, [history]);

  useEffect(() => {
    const msg = reqSended ? LOGGED_OUT_MESSAGE : LOGGING_OUT_MESSAGE;
    setReqMessage(msg);
  }, [reqSended]);

  useEffect(() => {
    let timeoutId;

    if (reqSended) {
      setShowRedirectMessage(true);

      timeoutId = setTimeout(() => {
        setTimeToRedirect(timeToRedirect - REDIRECT_TIME_DECREASE_INTERVAL);
      }, REDIRECT_TIME_DECREASE_INTERVAL * 1000);

      if (timeToRedirect <= 0) {
        logoutAction();
        redirect();
      }
    }

    return () => clearTimeout(timeoutId);
  }, [reqSended, redirect, timeToRedirect, logoutAction]);

  useEffect(() => {
    let timeoutId;

    const sendReq = () => {
      sendLogoutReq()
        .then(() => {
          setReqSended(true);
        })
        .catch(err => {
          if (err.response.status === 403) {
            // already logged out
            setReqSended(true);
          } else {
            // maybe a network error, try one more time
            timeoutId = setTimeout(() => {
              clearTimeout(timeoutId);
              sendReq();
            }, RETRY_REQUEST_INTERVAL * 1000);
          }
        });
    };

    if (!reqSended) {
      sendReq();
    }

    return () => clearTimeout(timeoutId);
  }, [reqSended]);

  return (
    <div className="logout">
      <div className="logout__logo" />
      <div className="logout__message">{reqMessage}</div>
      {showRedirectMessage && (
        <div className="logout__redirect-message">
          {REDIRECT_MESSAGE.replace('%time%', timeToRedirect)}
        </div>
      )}
    </div>
  );
}

export default connect(undefined, { logoutAction })(withRouter(Logout));
