import React, { useEffect, useState } from 'react';
import { Button, Divider, Table } from 'semantic-ui-react';

import './users.scss';
import { Link } from 'react-router-dom';
import http from '../../rest';
import { getFileURL } from '../../utils/get_document_url';
import { getSavedSort, saveSort, SORT_DESC } from '../../utils/localStorage';

const tableThs = [
  {
    name: 'Имя',
    key: 'ru_name',
  },
  {
    name: 'Name',
    key: 'en_name',
  },
  {
    name: 'Роль',
    key: 'role',
  },
  {
    name: 'Статус',
    key: 'state',
  },
];

function Users(props) {
  const [users, setUsers] = useState([]);
  const [sortDirection, setSortDirection] = useState(SORT_DESC);
  const [sortKey, setSortKey] = useState('');

  useEffect(() => {
    const [field, sort] = getSavedSort(`${Users.name}:sort`);

    let isSubscribed = true;
    http.api
      .get('/users', { params: { primary: true } })
      .then(users =>
        users.map(user => ({
          ...user,
          ru_name: `${user.lastName} ${user.firstName}`,
          en_name: `${user.lastName_en} ${user.firstName_en}`,
        }))
      )
      .then(users => {
        isSubscribed && setUsers(field ? sortUsers(users, field, sort) : users);
      });

    return function cleanup() {
      http.flush();
      isSubscribed = false;
    };
    // eslint-disable-next-line
  }, []);

  const addUser = () => {
    props.history.push('/users/new');
  };

  const sortUsers = (users, field, newSort = !sortDirection) => {
    setSortKey(field);
    setSortDirection(newSort);

    // сохраним сортировку в localStorage
    saveSort(`${Users.name}:sort`, field, newSort);

    users.sort(function(a, b) {
      const num = a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0;
      return (newSort && num) || -num;
    });

    return users;
  };

  const handleSortUsers = key => {
    const us = sortUsers(users, key);
    setUsers(us);
  };

  return (
    <div className="users">
      <div className="users__header">
        <div>Участники</div>
        <Button onClick={addUser}>Добавить участника</Button>
      </div>
      <Divider />
      <Table celled selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Фото</Table.HeaderCell>
            {tableThs.map(({ key, name }) => {
              const cn = ['users__sort'];
              if (sortKey === key) {
                cn.push('--active');
              }
              if (sortDirection === SORT_DESC) {
                cn.push('--desc');
              }
              return (
                <Table.HeaderCell key={key}>
                  <div className={cn.join(' ')} onClick={() => handleSortUsers(key)}>
                    {name}
                  </div>
                </Table.HeaderCell>
              );
            })}
            <Table.HeaderCell>Подпись</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {users.map(user => {
            return (
              <Table.Row key={user.phone}>
                <Table.Cell textAlign="center">
                  {user.photoId && (
                    <img src={getFileURL(user.photoId)} alt="" className="users__img" />
                  )}
                </Table.Cell>
                <Table.Cell width={5}>
                  <Link to={`/users/${user.phone}`}>{user.ru_name}</Link>
                </Table.Cell>
                <Table.Cell width={5}>
                  <Link to={`/users/${user.phone}`}>{user.en_name}</Link>
                </Table.Cell>
                <Table.Cell width={2}>{user.role}</Table.Cell>
                <Table.Cell width={2}>{user.state}</Table.Cell>

                <Table.Cell width={1} textAlign="center">
                  {user.signId && (
                    <img src={getFileURL(user.signId)} alt="" className="users__img" />
                  )}
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
}

export default Users;
