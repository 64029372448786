export async function getBlueStampImage() {
  return new Promise(resolve => {
    // get svg data
    const svgElement = document.getElementById('sign_svg');

    if (!svgElement) {
      resolve(null);
    }

    const svgXml = new XMLSerializer().serializeToString(svgElement);

    // make it base64
    const svgString = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svgXml)))}`;

    // считаем что на странице есть только один канвас
    const canvas = document.querySelector('canvas');

    // Set display size (css pixels).
    const width = 240;
    const height = 80;

    canvas.setAttribute('width', `${width}`);
    canvas.setAttribute('height', `${height}`);

    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';

    // Set actual size in memory (scaled to account for extra pixel density).
    const scale = 2; // window.devicePixelRatio; // Change to 1 on retina screens to see blurry canvas.
    const scaledWidth = width * scale;
    const scaledHeight = height * scale;

    canvas.width = scaledWidth;
    canvas.height = scaledHeight;

    const ctx = canvas.getContext('2d');

    const img = new Image();

    img.onload = event => {
      ctx.drawImage(img, 0, 0);

      canvas.toBlob(pngBlob => {
        resolve(pngBlob);
      }, 'image/png');
    };

    img.onerror = event => {
      console.error('image onerror ================', { event });
    };

    img.src = svgString;
    ctx.scale(scale, scale);
  });
}
