/** Не определен */
export const UNDEFINED = 'UNDEFINED';
/** Черновк */
export const DRAFT = 'DRAFT';
/** Удален */
export const DELETED = 'DELETED';
/** На согласовании (для утверждений) */
export const APPROVING = 'APPROVING';
/** На утверждении (для утверждений) */
export const APPROVED = 'APPROVED';
/** Отклонено (для утверждений) */
export const REJECTED = 'REJECTED';
/** Утвержденно (для утверждений) */
export const FINAL_APPROVED = 'FINAL_APPROVED';
/** Не утвержденно (для утверждений) */
export const FINAL_REJECTED = 'FINAL_REJECTED';
/** В работе (для поручений) */
export const EXECUTING = 'EXECUTING';
/** В доработке (для поручений) */
export const REWORKING = 'REWORKING';
/** Завершено (для поручений) */
export const EXECUTED = 'EXECUTED';
/** Ожидает моего ответа **/
export const MY_RESPONSE = 'MY_RESPONSE';
/** Ожидает моего решения **/
export const MY_APPROVING = 'MY_APPROVING';
