import 'semantic-ui-css/semantic.min.css';

import React from 'react';
import ReactDOM from 'react-dom';

import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';

import App from './app/app';
import * as serviceWorker from './serviceWorker';
import main_reducer from './store/main_reducer';
import { BrowserRouter } from 'react-router-dom';

import './index.scss';

// force reload page for Back button press in browser (root to user chenge case #HMS-995)
if (document.addEventListener) {
  window.addEventListener(
    'pageshow',
    event => {
      if (event.persisted || (window.performance && window.performance.navigation.type === 2)) {
        setTimeout(() => window.location.reload(true), 350);
      }
    },
    false
  );
}

const store = createStore(main_reducer, applyMiddleware(thunk));

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
