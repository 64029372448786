import http from './http';

http
  .rest({
    name: 'api',
    baseURL: `/api`,
  })
  .rest({
    name: 'rpc',
    baseURL: `/api/rpc/v1/call`,
  });

export default http;
