export const SORT_ASC = true;
export const SORT_DESC = !SORT_ASC;

export function getSavedSort(name) {
  const sort = JSON.parse(localStorage.getItem(name)) || {};
  const keys = Object.keys(sort);

  if (keys.length) {
    const field = keys[0];
    const sortAsc = sort[field];

    return [field, sortAsc];
  } else {
    return ['', SORT_DESC];
  }
}

export function saveSort(name, field, sort) {
  localStorage.setItem(name, JSON.stringify({ [field]: sort }));
}
