import React from 'react';
import PropTypes from 'prop-types';

import { Button, Card, Segment, Modal } from 'semantic-ui-react';

import http from '../../../../rest';
import { REMOVED } from '../../constants/states';
import { SecretaryCard, AddNewSecretaryCard } from './SecretaryCard';

import './Secretaries.scss';
import { withRouter } from 'react-router-dom';

function Secretaries(props) {
  const { lang, primaryUser, history } = props;

  const [secretaries, setSecretaries] = React.useState([]);
  const [toRemove, setToRemove] = React.useState(undefined);
  const [isOpenModal, setOpenModal] = React.useState(false);

  const getSecretaries = React.useCallback(() => {
    if (primaryUser && primaryUser.primary) {
      http.api
        .get('/users', {
          params: {
            accountIdList: primaryUser.accountId,
            primary: false,
          },
        })
        .then(users => {
          setSecretaries(users);
        });
    }
  }, [primaryUser]);

  React.useEffect(() => {
    getSecretaries();
  }, [getSecretaries, primaryUser]);

  const onEditUser = user => {
    history.push({
      pathname: `/users/${user.phone}/${primaryUser.accountId || ''}`,
      state: { primaryUser },
    });
  };

  const openModal = React.useCallback(() => {
    setOpenModal(true);
  }, []);

  const closeModal = React.useCallback(() => {
    setOpenModal(false);
    setToRemove(undefined);
  }, []);

  const onRemoveUser = React.useCallback(
    user => {
      setToRemove(user);
      openModal();
    },
    [openModal]
  );

  const removeSecretary = React.useCallback(() => {
    const fd = new FormData();
    fd.set('user', JSON.stringify({ ...toRemove, state: REMOVED }));
    http.api
      .put('/users', fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      })
      .then(() => {
        getSecretaries();
        closeModal();
      });
  }, [closeModal, getSecretaries, toRemove]);

  const onAddUser = e => {
    e.preventDefault();
    history.push({ pathname: `/users/new/${primaryUser.accountId || ''}`, state: { primaryUser } });
  };

  return (
    <Card.Group>
      <Segment basic>
        <AddNewSecretaryCard onAdd={onAddUser} />
      </Segment>
      {secretaries.map(u => (
        <Segment basic key={u.id}>
          <SecretaryCard lang={lang} user={u} onEdit={onEditUser} onRemove={onRemoveUser} />
        </Segment>
      ))}
      <Modal
        open={isOpenModal}
        closeOnEscape={true}
        closeOnDimmerClick={true}
        onClose={closeModal}
        size="tiny">
        <Modal.Header>Удаление секретаря</Modal.Header>
        <Modal.Content>
          <p>Удаление секретаря необратимо. Вы уверены что хотите продолжить?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={closeModal} color="grey">
            Нет
          </Button>
          <Button onClick={removeSecretary} color="red">
            Да
          </Button>
        </Modal.Actions>
      </Modal>
    </Card.Group>
  );
}

Secretaries.defaultProps = {
  primaryUser: undefined,
  lang: 'RUS',
};

Secretaries.propTypes = {
  users: PropTypes.array,
  primaryUser: PropTypes.object,
  lang: PropTypes.oneOf(['RUS', 'ENG']),
};

export default withRouter(Secretaries);
