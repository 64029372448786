export const UNDEFINED = 'UNDEFINED';
/** На согласовании*/
export const REQUESTED = 'REQUESTED';
/** Согласовано */
export const APPROVED = 'APPROVED';
/** Отклонено */
export const REJECTED = 'REJECTED';
/** Рассмотрено */
export const EXECUTED = 'EXECUTED';
/** Ожидает моего решения **/
export const MY_REQUESTED = 'MY_REQUESTED';
/** Ожидает моего ответа **/
export const MY_RESPONSE = 'MY_RESPONSE';
/** На утверждении **/
export const FINAL_REQUESTED = 'FINAL_REQUESTED';
/** Утверждено **/
export const FINAL_APPROVED = 'FINAL_APPROVED';
/** Не утверждено **/
export const FINAL_REJECTED = 'FINAL_REJECTED';
/** На доработке **/
export const REWORKING = 'REWORKING';
/** В работе **/
export const EXECUTING = 'EXECUTING';
/** Вернул на доработку **/
export const RETURNED_FOR_REWORKING = 'RETURNED_FOR_REWORKING';
/** Ответ подготовлен **/
export const RESPONSE_PREPARED = 'RESPONSE_PREPARED';
/* Пропущен при согласовании (если утверждающий утвердил пакет/поручение, а пользователь был в нем согласующим и еще не дал ответа) */
export const SKIPPED = 'SKIPPED';
/* Ответ не получен */
export const EXPIRED = 'EXPIRED';
