import React from 'react';
import PropTypes from 'prop-types';

import {
  APPROVED,
  FINAL_APPROVED,
  EXECUTED,
  RESPONSE_PREPARED,
  REJECTED,
  FINAL_REJECTED,
  RETURNED_FOR_REWORKING,
  SKIPPED,
  EXPIRED,
} from '../../../constants/decision_business_statuses';
import { daysPlural } from '../../../utils/plurals';

function InWork({ daysSpent, status }) {
  const completedStatuses = [
    APPROVED,
    FINAL_APPROVED,
    EXECUTED,
    RESPONSE_PREPARED,
    REJECTED,
    FINAL_REJECTED,
    RETURNED_FOR_REWORKING,
    EXPIRED,
    SKIPPED,
  ];

  const label = completedStatuses.includes(status) ? 'За' : 'В работе';

  return <div className="in_work">{`${label} ${daysSpent} ${daysPlural(daysSpent)}`}</div>;
}

InWork.propTypes = {
  daysSpent: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
};

export default InWork;
