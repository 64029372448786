import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { Button, Dropdown, Form, Message } from 'semantic-ui-react';
import './auth.scss';
import http from '../../rest';
import { authUser } from '../../store/user/actions';
import { connect } from 'react-redux';
import { ROLE_ADMIN, ROLE_ROOT } from '../../constants/roles';
import {
  REDIRECT_TIME,
  REDIRECT_TIME_DECREASE_INTERVAL,
  REDIRECT_MESSAGE,
} from '../../constants/logout';

const phoneCodes = [
  {
    key: '+1',
    text: '+1',
    value: '1',
  },
  {
    key: '+7',
    text: '+7',
    value: '7',
  },
  {
    key: '+33',
    text: '+33',
    value: '33',
  },
  {
    key: '+44',
    text: '+44',
    value: '44',
  },
  {
    key: '+357',
    text: '+357',
    value: '357',
  },
  {
    key: '+377',
    text: '+377',
    value: '377',
  },
];
const MAX_LENGTH = 4;
const BLOCK_USER_CODE = 107;

function Auth({ authUser, history }) {
  const [dataByPhone, setDataByPhone] = useState(null);
  const [code, setCode] = useState(phoneCodes[1].value);
  const [error, setError] = useState('');
  const [sms, setSms] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userBlocked, setUserBlocked] = useState(false);
  const [timeToRedirect, setTimeToRedirect] = useState(REDIRECT_TIME);

  const redirect = useCallback(() => {
    setDataByPhone('');
    setPhoneNumber('');
    setPhone('');
    setSms('');
    setError('');
    setUserBlocked(false);
    setTimeToRedirect(REDIRECT_TIME);
  }, []);

  useEffect(() => {
    let timeoutId;

    if (userBlocked) {
      timeoutId = setTimeout(() => {
        setTimeToRedirect(timeToRedirect - REDIRECT_TIME_DECREASE_INTERVAL);
      }, REDIRECT_TIME_DECREASE_INTERVAL * 1000);

      if (timeToRedirect <= 0) {
        redirect();
      }
    }

    return () => clearTimeout(timeoutId);
  }, [userBlocked, redirect, timeToRedirect]);

  const onChangeCode = (e, { value }) => {
    setCode(value);
  };
  const authByPhone = () => {
    if (phoneNumber !== '') {
      const phone = code + phoneNumber;
      http.api.post('/public/login/phone', { phone }).then(({ data, status }) => {
        setDataByPhone(data);
        setPhone(phone);
      });
    }
  };
  const authBySms = ({ target: { value } }) => {
    if (value.length === MAX_LENGTH) {
      http.api
        .post('/public/login/code', {
          phone: phone,
          otp: value,
          nonce: dataByPhone.nonce,
        })
        .then(({ data, status }) => {
          if (status) {
            setSms(value);
            setError('');
          } else {
            setError(data.message);
          }
        });
    }
  };
  const authByPin = ({ target: { value } }) => {
    if (userBlocked) {
      return;
    }
    if (value.length === MAX_LENGTH) {
      http.api
        .post('/public/login/complete', {
          phone: phone,
          otp: sms,
          nonce: dataByPhone.nonce,
          pin: value,
        })
        .then(({ data }) => {
          const { userDetails = {} } = data;
          if (
            typeof data.token === 'string' &&
            [ROLE_ROOT, ROLE_ADMIN].includes(userDetails.role)
          ) {
            authUser(userDetails);
            setError('');
            history.push({ pathname: '/' });
          } else {
            setError(data.message || 'Нет доступа');
            if (data.code === BLOCK_USER_CODE) {
              setUserBlocked(true);
            }
          }
        });
    }
  };
  const onPhoneNumberChange = ({ target: { value } }) => setPhoneNumber(value);
  const isPhoneInput = phone === '';
  const isSmsVisible = phone !== '' && sms === '';
  const isError = error !== '';
  const isPinVisible = sms !== '';
  return (
    <div className="auth">
      <div className="auth__container">
        <div className="auth__header">Панель администратора</div>
        {isPhoneInput && (
          <Fragment>
            <div className="auth__info">Введите свой номер телефона</div>
            <Form>
              <Form.Group inline>
                <Form.Field>
                  <label>Код страны</label>
                  <Dropdown options={phoneCodes} onChange={onChangeCode} value={code} />
                </Form.Field>
              </Form.Group>
              <Form.Group inline>
                <Form.Field>
                  <label>Номер</label>
                  <input onChange={onPhoneNumberChange} value={phoneNumber} autoFocus={true} />
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <Button type="button" color="blue" size="large" onClick={authByPhone}>
                  Войти
                </Button>
              </Form.Field>
            </Form>
          </Fragment>
        )}
        {isSmsVisible && (
          <div className="auth__info">
            Введите код, который мы выслали вам в SMS на номер +{phone}.
          </div>
        )}
        <Form error={isError}>
          {isSmsVisible && (
            <Form.Field width={4}>
              <label>СМС</label>
              <input onChange={authBySms} maxLength={MAX_LENGTH} autoFocus={true} />
            </Form.Field>
          )}
          {isPinVisible && (
            <Form.Field width={4}>
              <label>ПИН</label>
              <input onChange={authByPin} type="password" maxLength={MAX_LENGTH} autoFocus={true} />
            </Form.Field>
          )}
          <Message error content={error} className="auth__error" />
          {userBlocked && (
            <div className="auth__redirect">
              {REDIRECT_MESSAGE.replace('%time%', timeToRedirect)}
            </div>
          )}
        </Form>
      </div>
    </div>
  );
}

const mapDispatchToProps = { authUser };
export default connect(null, mapDispatchToProps)(Auth);
