import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';

export const formatDate = (data, withTime = false) => {
  if (data) {
    const date = new Date(data * 1000);
    const pattern = withTime ? 'dd MMM yyyyг., H:mm' : 'dd MMM yyyyг.';
    return format(date, pattern, { locale: ru });
  }
  return '';
};

export default formatDate;
