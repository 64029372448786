const PluralRules = new Intl.PluralRules('ru-RU');

export function commentsPlural(number) {
  switch (PluralRules.select(number)) {
    case 'one':
      return 'комментарий';
    case 'two':
      return 'комментария';
    case 'few':
      return 'комментария';
    case 'many':
      return 'комментариев';
    default:
      return 'комментариев';
  }
}

export function daysPlural(number) {
  switch (PluralRules.select(number)) {
    case 'one':
      return 'день';
    case 'two':
      return 'дня';
    case 'few':
      return 'дня';
    case 'many':
      return 'дней';
    default:
      return 'дней';
  }
}
