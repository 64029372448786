import React from 'react';
import './users-new.scss';
import { Breadcrumb } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import UsersForm from '../form';
import http from '../../../rest';

function NewUser({ history, location, match }) {
  const accountId = match.params.accountId;
  const primaryUser = location.state && location.state.primaryUser;

  const onSubmit = (user, photo, sign) => {
    const fd = new FormData();
    fd.set('user', JSON.stringify({ ...user, accountId }));
    if (photo) {
      fd.set('photo', photo);
    }
    if (sign) {
      fd.set('sign', sign, `${user.lastName}_${user.firstName}_sign.png`);
    }
    return http.api
      .post('/users', fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      })
      .then(addedUser => {
        if (primaryUser && primaryUser.phone) {
          history.push({ pathname: `/users/${primaryUser.phone}` });
        } else {
          history.push({ pathname: `/users/${addedUser.phone}` });
        }
      });
  };

  const goBack = e => {
    e.preventDefault();
    if (primaryUser && primaryUser.phone) {
      history.push({ pathname: `/users/${primaryUser.phone}` });
    } else {
      history.push({ pathname: '/users' });
    }
  };

  return (
    <div className="users-new">
      <div className="users-new__header">
        <Breadcrumb>
          <Breadcrumb.Section link as={Link} to="/users">
            Участники
          </Breadcrumb.Section>
          <Breadcrumb.Divider />
          <Breadcrumb.Section active>Добавление</Breadcrumb.Section>
        </Breadcrumb>
      </div>
      <div className="users-new__form">
        <UsersForm
          onSubmit={onSubmit}
          isNew={true}
          isPrimaryUser={!Boolean(accountId)}
          onGoBack={goBack}
        />
      </div>
    </div>
  );
}

export default NewUser;
