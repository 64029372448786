import * as types from './action_types';
import http from '../../rest';
import { ROLE_ADMIN, ROLE_ROOT } from '../../constants/roles';

export const setUserAuth = authenticated => ({
  type: types.SET_USER_AUTH,
  payload: authenticated,
});

export const authCheckState = () => dispatch => {
  http.api
    .get('/me')
    .then(user => {
      if ([ROLE_ROOT, ROLE_ADMIN].includes(user.role)) dispatch(authUser(user));
      else dispatch(logoutUser());
    })
    .catch(_ => {
      dispatch(setUserAuth(false));
    });
};

export const authUser = user => {
  return {
    type: types.AUTH_USER,
    payload: user,
  };
};

export const sendLogoutReq = () => {
  return http.api.post('/auth/logout');
};

export const logoutAction = () => dispatch => {
  dispatch({
    type: types.LOGOUT_USER,
  });
};

export const logoutUser = () => dispatch => {
  sendLogoutReq().then(() => {
    dispatch(logoutAction());
  });
};
