import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import previewImg from '../../images/preview-img.jpg';
import './document_preview_list.scss';
import formatBytesToSize from '../../utils/file_size_utils';
import { getFileURL } from '../../utils/get_document_url';
import { getExtension } from '../../utils/get_extension';
import formatDate from '../../utils/date_utils';

import debounce from 'lodash/fp/debounce';

const MARK_VIEWED_TIMEOUT = 500;

const DocumentPreviewList = ({ docList: pristineDocList, title, forceViewed }) => {
  const [docList, setDocList] = useState(() => {
    const docs = pristineDocList || [];
    return forceViewed ? markAllViewed(docs) : docs;
  });

  useEffect(() => {
    if (forceViewed) {
      setDocList(markAllViewed(pristineDocList));
    } else {
      setDocList(pristineDocList);
    }
  }, [pristineDocList, forceViewed]);

  const markViewed = useCallback(
    debounce(MARK_VIEWED_TIMEOUT, docId => {
      setDocList(docs =>
        docs.map(d => {
          if (d.id === docId) {
            d.new = false;
          }
          return d;
        })
      );
    }),
    [setDocList]
  );

  return (
    <div className="preview">
      {title !== '' && (
        <div className="preview__header">
          <div className="preview__header_text">{title}</div>
        </div>
      )}
      <div className="preview__items">
        {docList.map((doc, docIndex) => {
          const extension = getExtension(doc.originalFileId);
          const isRemoved = Boolean(doc.deletedDate);
          const isNew = Boolean(doc.new);
          return (
            <div className="preview__item" key={docIndex}>
              <div
                className={`preview__item_icon --${extension} ${isRemoved ? '--removed' : ''}`}
                onClick={() => {
                  markViewed(doc.id);
                  openPdf(doc.originalFileId);
                }}>
                {isNew && <div className="preview__item_icon__new" />}
              </div>
              <a
                href={getFileURL(doc.originalFileId)}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => markViewed(doc.id)}>
                {doc.fileName}
              </a>
              <div className="preview__item_size">{formatBytesToSize(doc.size)}</div>
              {isRemoved && (
                <>
                  <div className="preview__item_date">{`Загружен: ${formatDate(
                    new Date(doc.createdDate),
                    true
                  )}`}</div>
                  <div className="preview__item_date">{`Удалён: ${formatDate(
                    new Date(doc.deletedDate),
                    true
                  )}`}</div>
                </>
              )}
              <a
                className="preview__item_download"
                href={getFileURL(doc.originalFileId)}
                onClick={() => markViewed(doc.id)}
                download>
                Скачать
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const openPdf = fileId => {
  if (fileId) window.open(getFileURL(fileId), '_blank');
};

DocumentPreviewList.propTypes = {
  docList: PropTypes.array,
  title: PropTypes.string,
  forceViewed: PropTypes.bool,
};

DocumentPreviewList.defaultProps = {
  docList: [
    {
      previewLink: previewImg,
      fileName: 'Файл',
    },
  ],
  title: '',
  forceViewed: false,
};

export default DocumentPreviewList;

// Utils

function markAllViewed(docs) {
  return docs.map(d => ({ ...d, new: false }));
}
