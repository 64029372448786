import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

import isEmpty from 'lodash/fp/isEmpty';

import './file-manager-view.scss';
import http from '../../../rest';
import {
  Breadcrumb,
  Button,
  Divider,
  Icon,
  Input,
  Item,
  Message,
  Modal,
  Transition,
} from 'semantic-ui-react';
import Attach from '../attach';
import Decision from '../decision';
import DocView from '../doc_view';
import { DOCUMENT_TITLE_MAX_LENGTH, DOCUMENT_DESCRIPTION_MAX_LENGTH } from '../constants/documents';
import statusesMap from '../../../constants/document_package_business_status_map';
import { DELETED } from '../../../constants/document_package_business_status';
import formatUserName from '../../../utils/string_utils';
import { formatDate } from '../../../utils';
import { debounce } from '../../../utils/form';

import { VIEW_APPROVAL_SHEET } from '../../../constants/permissions';
import { getApprovementUrl } from '../../../utils/get_document_url';
import DocumentPreviewList from '../../../components/document_preview_list';

const MESSAGE_TIMEOUT = 5000;

function FileManagerView({ match, history }) {
  const { id } = match.params;
  const [document, setDocument] = useState(null);
  const [docTitle, setDocTitle] = useState('');
  const [docTitleEn, setDocTitleEn] = useState('');
  const [docDescr, setDocDescr] = useState('');
  const [docDescrEn, setDocDescrEn] = useState('');
  const [sending, setSending] = useState({});
  const [formError, setFormError] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [documentsRu, setDocumentsRu] = useState([]);
  const [documentsEng, setDocumentsEng] = useState([]);
  const [removedVisible, setRemovedVisible] = useState(false);

  const { removedDocuments } = document || { removedDocuments: [] };
  const haveRemovedDocuments = !isEmpty(removedDocuments);
  const collapseText = removedVisible
    ? 'Скрыть удалённые файлы'
    : `Удалённые файлы (${removedDocuments.length})`;
  const toggleRemoved = () => setRemovedVisible(visible => !visible);

  const packageUpdate = ({
    title,
    title_en,
    description,
    description_en,
    documentsRu,
    documentsEng,
  }) => {
    setFormError('');
    return http.rpc
      .post('/updateDocumentPackageAdmin', {
        id: document.id,
        title,
        title_en,
        description,
        description_en,
        status: document.status,
        watchers: document.watchers.map(({ id }) => id),
        documentsRu: documentsRu.map(({ id }) => id),
        documentsEng: documentsEng.map(({ id }) => id),
        removedDocuments: removedDocuments.map(({ id }) => id),
      })
      .then(resp => {
        if (!resp.status || resp.data.code === 500) {
          setFormError(resp.data.message);
        }
        setSending(s => ({
          ...s,
          docTitle: false,
          docDescr: false,
          docTitleEn: false,
          docDescrEn: false,
        }));
      })
      .catch(err => {
        setFormError(err.message);
        setSending(s => ({
          ...s,
          docTitle: false,
          docDescr: false,
          docTitleEn: false,
          docDescrEn: false,
        }));
      });
  };

  const debouncePackageUpdate = useCallback(debounce(packageUpdate, 500), [document]);

  const preparePackageUpdateData = (replaceData = {}) => {
    return {
      title: docTitle,
      title_en: docTitleEn,
      description: docDescr,
      description_en: docDescrEn,
      documentsRu,
      documentsEng,
      ...replaceData,
    };
  };

  const onDocTitleChange = (evt, { value }) => {
    setDocTitle(value);
    setSending(s => ({ ...s, docTitle: true }));
    debouncePackageUpdate(
      preparePackageUpdateData({
        title: value,
      })
    );
  };

  const onDocTitleEnChange = (evt, { value }) => {
    setDocTitleEn(value);
    setSending(s => ({ ...s, docTitleEn: true }));
    debouncePackageUpdate(
      preparePackageUpdateData({
        title_en: value,
      })
    );
  };

  const onDocDescrChange = (evt, { value }) => {
    setDocDescr(value);
    setSending(s => ({ ...s, docDescr: true }));
    debouncePackageUpdate(
      preparePackageUpdateData({
        description: value,
      })
    );
  };

  const onDocDescrEnChange = (evt, { value }) => {
    setDocDescrEn(value);
    setSending(s => ({ ...s, docDescrEn: true }));
    debouncePackageUpdate(
      preparePackageUpdateData({
        description_en: value,
      })
    );
  };

  useEffect(() => {
    const id = setTimeout(() => {
      setFormError('');
    }, MESSAGE_TIMEOUT);
    return () => clearTimeout(id);
  }, [formError]);

  useEffect(() => {
    http.api.get(`/documents/${id}`).then(doc => {
      setDocument(doc);
      setDocTitle(doc.title);
      setDocTitleEn(doc.title_en || '');
      setDocDescr(doc.description);
      setDocDescrEn(doc.description_en || '');
      setDocumentsRu(doc.documentsRu);
      setDocumentsEng(doc.documentsEng);
    });
  }, [id]);

  const deleteDocument = () => {
    setIsDelete(false);
    http.api.delete(`/documents/${id}`).then(() => {
      history.replace('/file-manager');
    });
  };

  const deleteDoc = (docId, type) => {
    let docsRu = documentsRu.slice();
    let docsEng = documentsEng.slice();
    if (type === 'ru') {
      docsRu = docsRu.filter(({ id }) => id !== docId);
    } else {
      docsEng = docsEng.filter(({ id }) => id !== docId);
    }
    docsUpdate(docsRu, docsEng);
  };

  const addDocs = (docs, type) => {
    let docsRu = documentsRu.slice();
    let docsEng = documentsEng.slice();
    if (type === 'ru') {
      docsRu = docsRu.concat(docs);
    } else {
      docsEng = docsEng.concat(docs);
    }
    docsUpdate(docsRu, docsEng);
  };

  const docsUpdate = (docsRu, docsEng) => {
    packageUpdate({
      title: docTitle,
      title_en: docTitleEn,
      description: docDescr,
      description_en: docDescrEn,
      documentsRu: docsRu,
      documentsEng: docsEng,
    }).then(() => {
      setDocumentsRu(docsRu);
      setDocumentsEng(docsEng);
    });
  };

  const type = document && document.type;

  const title =
    (document && document.title) || (type === 'TASK' ? 'Поручение' : 'Пакет документов');

  const canViewApprovalList =
    document && document.permissions && document.permissions.includes(VIEW_APPROVAL_SHEET);

  return (
    document && (
      <div className="file-manager-view">
        <div className="file-manager-view__header">
          <Breadcrumb>
            <Breadcrumb.Section link as={Link} to="/file-manager">
              Файловый менеджер
            </Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section active>{title}</Breadcrumb.Section>
          </Breadcrumb>
        </div>
        <Divider />
        <Item.Group>
          <Item>
            <Item.Content>
              <Item.Description>
                <div className="file-manager-view__title">Дата создания</div>
                {formatDate(document.createdDate, true)}
                {document.lastStartedDate &&
                  document.lastStartedDate !== document.createdDate &&
                  `, изменено ${formatDate(document.lastStartedDate, true)}`}
              </Item.Description>
              <Item.Description>
                <div className="file-manager-view__title">Краткое название</div>
                <Input
                  fluid
                  placeholder="на русском"
                  value={docTitle}
                  onChange={onDocTitleChange}
                  maxLength={DOCUMENT_TITLE_MAX_LENGTH}
                  loading={sending.docTitle}
                />
              </Item.Description>
              <Item.Description>
                <Input
                  fluid
                  placeholder="на английском"
                  value={docTitleEn}
                  onChange={onDocTitleEnChange}
                  maxLength={DOCUMENT_TITLE_MAX_LENGTH}
                  loading={sending.docTitleEn}
                />
              </Item.Description>

              <Item.Description>
                <div className="file-manager-view__title">Создатель</div>
                {formatUserName(document.createdBy)}
              </Item.Description>
              <Item.Description>
                <div className="file-manager-view__title">Статус</div>
                {statusesMap[document.businessStatus]}
              </Item.Description>
              <Item.Description>
                <div className="file-manager-view__title">Описание</div>
                <Input
                  fluid
                  placeholder="на русском"
                  value={docDescr}
                  onChange={onDocDescrChange}
                  maxLength={DOCUMENT_DESCRIPTION_MAX_LENGTH}
                  loading={sending.docDescr}
                />
              </Item.Description>
              <Item.Description>
                <Input
                  fluid
                  placeholder="на английском"
                  value={docDescrEn}
                  onChange={onDocDescrEnChange}
                  maxLength={DOCUMENT_DESCRIPTION_MAX_LENGTH}
                  loading={sending.docDescrEn}
                />
              </Item.Description>
              <Item.Description>
                <div className="file-manager-view__title">Кому</div>
                <div className="file-manager-view__people">
                  {type === 'APPROVEMENT' &&
                    document.approvers.map(approver => (
                      <div key={approver.id}>{formatUserName(approver)}</div>
                    ))}
                  {type === 'TASK' &&
                    document.executors.map(executor => (
                      <div key={executor.id}>{formatUserName(executor)}</div>
                    ))}
                </div>
              </Item.Description>
              <Item.Description>
                <div className="file-manager-view__title">Документы:</div>
                <DocView
                  documents={documentsRu}
                  label="рус"
                  type="initiator"
                  deleteDoc={id => deleteDoc(id, 'ru')}
                />
                <Attach onUpload={docs => addDocs(docs, 'ru')} />
                <DocView
                  documents={documentsEng}
                  label="eng"
                  type="initiator"
                  deleteDoc={id => deleteDoc(id, 'eng')}
                />
                <Attach onUpload={docs => addDocs(docs, 'eng')} />
              </Item.Description>
              <Item.Description>
                {removedVisible && (
                  <div className="coordination__files_removed">
                    <DocumentPreviewList docList={removedDocuments} forceViewed={true} />
                  </div>
                )}

                {haveRemovedDocuments && (
                  <div className="coordination__files_more --executor" onClick={toggleRemoved}>
                    {collapseText}
                  </div>
                )}
              </Item.Description>
              <Item.Description>
                <div className="file-manager-view__title">Согласование</div>
                {document.decisions.map(decision => (
                  <Decision decision={decision} key={decision.id} type={type} />
                ))}
              </Item.Description>
              {document.watchers.length > 0 && (
                <Item.Description>
                  <div className="file-manager-view__title">Добавлены в копию</div>
                  <div className="file-manager-view__people">
                    {document.watchers.map(watcher => (
                      <div key={watcher.id}>{formatUserName(watcher)}</div>
                    ))}
                  </div>
                </Item.Description>
              )}
              {canViewApprovalList && (
                <Item.Description>
                  <div className="file-manager-view__people">
                    <a
                      className="request-view__agreement_list_dl"
                      href={getApprovementUrl(document.id)}
                      download>
                      Скачать лист согласования
                    </a>
                    <div />
                  </div>
                </Item.Description>
              )}
              {document.status !== DELETED && (
                <Item.Description>
                  <Button color="red" onClick={() => setIsDelete(true)}>
                    Удалить
                  </Button>
                </Item.Description>
              )}
            </Item.Content>
          </Item>
        </Item.Group>
        <Modal size="tiny" open={isDelete} onClose={() => setIsDelete(false)}>
          <Modal.Header>Удаление</Modal.Header>
          <Modal.Content>
            <p>
              Вы уверены, что хотите удалить {type === 'TASK' ? 'поручение' : 'пакет документов'}?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button negative content="Нет" onClick={() => setIsDelete(false)} />
            <Button positive content="Да" onClick={deleteDocument} />
          </Modal.Actions>
        </Modal>
        <Transition visible={Boolean(formError)} animation="scale" duration={500}>
          <Message attached="bottom" error>
            <Icon name="times" />
            {formError}
          </Message>
        </Transition>
      </div>
    )
  );
}

export default FileManagerView;
