import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon, Label } from 'semantic-ui-react';
import './photo-input-preview.scss';
import uniqueId from '../../utils/unique_id';

export class PhotoInputPreview extends Component {
  state = { photos: [], files: [], key: 'img' + uniqueId() };

  constructor(props) {
    super(props);
    this.loadFiles(props.files || []);
  }

  static getDerivedStateFromProps({ files }) {
    return { files };
  }

  onChange = e => {
    const files = (e_files => {
      let res = [];
      let len = this.props.amount - this.state.files.length;
      if (len > e_files.length) {
        len = e_files.length;
      }
      for (let i = 0; i < len; i++) {
        let f = e_files[i];
        if (!this.hasExtension(f.name)) {
          continue;
        }
        if (f.size > this.props.maxFileSize) {
          continue;
        }
        const checkCopy = this.state.files.some(
          file => file.name === f.name && file.size === f.size
        );
        if (checkCopy) {
          continue;
        }
        res.push(f);
      }
      return res;
    })(e.target.files);
    if (files.length) {
      this.setFiles(this.state.files.concat(files));
    }
  };

  hasExtension = file_name => {
    return new RegExp('(' + this.props.imgExtension.join('|').replace(/\./g, '\\.') + ')$').test(
      file_name.toLowerCase()
    );
  };

  setFiles = (files, photos = this.state.photos.slice()) => {
    this.setState({ files, photos });
    this.props.onChange(files);
  };

  componentDidUpdate = () => {
    this.loadFiles(this.props.files || []);
  };

  loadFiles = files => {
    for (let i = 0, file; i < files.length; i++) {
      file = files[i];
      let reader = new FileReader();
      reader.onload = e => {
        const index = this.state.photos.findIndex(photo => photo.src === e.target.result);
        if (index === -1) {
          const newArray = this.state.photos.slice();
          newArray.push({ src: e.target.result, i });
          this.setState({ photos: newArray.sort((a, b) => +(a.i > b.i)) });
        }
      };
      reader.readAsDataURL(file);
    }
  };
  renderPreviewPhotos = () => {
    if (!this.state.photos || this.state.photos.length === 0) {
      return null;
    }
    return (
      <div className="photo-input-preview__container">
        {this.state.photos.map((photo, index) => (
          <div className="photo-input-preview__item" key={photo.i}>
            <Icon
              name="delete"
              className="photo-input-preview__delete"
              onClick={() => {
                this.removePhoto(index);
              }}
            />
            <img src={photo.src} alt="preview" />
          </div>
        ))}
      </div>
    );
  };

  removePhoto = index => {
    let files = this.state.files.slice();
    let photos = this.state.photos.slice();
    files.splice(index, 1);
    photos.splice(index, 1);
    this.setFiles(files, photos);
  };
  renderAcceptableImageInfo = () => {
    const { names } = this.props;
    const maxFileSize = (this.props.maxFileSize / 1024 / 1024).toLocaleString() + 'мб';
    return (
      <div>
        Максимальный размер {names[1]}: {maxFileSize}. Можно загрузить {this.props.amount}{' '}
        {this.photoNounOnAmount()}
      </div>
    );
  };
  photoNounOnAmount = () => {
    const { amount, names } = this.props;
    let num = amount % 10;
    let temp = amount % 100;
    if (num > 0 && num < 5) {
      if (num === 1) return names[0];
      if (temp > 10 && temp < 15) {
        return names[2];
      }
      return names[1];
    }
    return names[2];
  };
  renderFileInput = () => {
    const { amount, names, imgExtension } = this.props;
    const { key } = this.state;
    if (this.state.files.length === amount) {
      return null;
    }
    return (
      <Fragment>
        <label htmlFor={key} className="photo-input-preview__label">
          <Label color="blue" key="blue">
            Загрузить {names[0]}
          </Label>
        </label>
        <input
          type="file"
          id={key}
          multiple
          onChange={this.onChange}
          accept={imgExtension.join(',')}
        />
      </Fragment>
    );
  };

  render() {
    return (
      <div className="photo-input-preview">
        {this.renderFileInput()}
        {this.renderAcceptableImageInfo()}
        {this.renderPreviewPhotos()}
      </div>
    );
  }
}

PhotoInputPreview.defaultProps = {
  imgExtension: ['.jpg', '.jpeg', '.gif', '.png'],
  maxFileSize: 2097512,
  onChange: _ => _,
  amount: 1,
  names: ['файл', 'файла', 'файлов'],
};

PhotoInputPreview.propTypes = {
  files: PropTypes.array.isRequired,
  imgExtension: PropTypes.array,
  maxFileSize: PropTypes.number,
  amount: PropTypes.number,
  onChange: PropTypes.func,
  names: PropTypes.array,
};
