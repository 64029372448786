import React, { Fragment, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import './users-form.scss';
import {
  Accordion,
  Button,
  Checkbox,
  Form,
  Icon,
  Message,
  Modal,
  Segment,
  Transition,
  Dropdown,
} from 'semantic-ui-react';

import { COMMON_BLOCK, defaultUser, LANG_BLOCK_EN, LANG_BLOCK_RU } from '../constants/vars';
import { ACTIVE, LOCKED, REMOVED, NEW } from '../constants/states';
import { PhotoInputPreview } from '../../../components';
import { Secretaries } from './secretaries';
import http from '../../../rest';
import {
  ROLE_ADMIN,
  ROLE_APPROVER,
  ROLE_FINAL_APPROVER,
  ROLE_INITIATOR,
} from '../../../constants/roles';

import { BlueStampSvg } from '../../../components';
import { getBlueStampImage, getFileURL, getWebClientUrl } from '../../../utils';
import formatUserName from '../../../utils/string_utils';

const roles = [ROLE_FINAL_APPROVER, ROLE_APPROVER, ROLE_INITIATOR, ROLE_ADMIN];
const REQUIRED_FIELDS = ['lastName', 'firstName', 'lastName_en', 'firstName_en', 'phone'];
const [RUS, ENG] = [1, 2];
const MAX_LENGTH = 250;
const MESSAGE_TIMEOUT = 5000;

const userStatus = {
  [NEW]: 'НОВЫЙ',
  [REMOVED]: 'УДАЛЕН',
  [LOCKED]: 'ЗАБЛОКИРОВАН',
};

function UsersForm({ onSubmit, isNew, isPrimaryUser, data, photo, sign, onGoBack }) {
  const [formSuccess, setFormSuccess] = useState(false);
  const [formError, setFormError] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorEng, setIsErrorEng] = useState(false);
  const [error, setError] = useState({});
  const [user, setUser] = useState(data);
  const [tab, setTab] = useState(RUS);
  const [files, setFiles] = useState([]);
  const [signs, setSigns] = useState([sign]);
  const [resetPin, setResetPin] = useState(false);
  const [finishResetPin, setFinishResetPin] = useState(false);
  const [lockText, setLockText] = useState('');
  const [approversSelectItems, setApproversSelectItems] = useState(null);

  const [expandSecretaries, setExpandSecretaries] = useState(true);

  const handleExpandSecretaries = useCallback(() => {
    setExpandSecretaries(!expandSecretaries);
  }, [expandSecretaries]);

  useEffect(() => {
    if (user.role !== ROLE_INITIATOR && user.observer) {
      setUser(
        Object.assign({}, user, {
          observer: false,
          megafonEmployee: false,
          corporateApprover: false,
        })
      );
    }
  }, [user]);

  useEffect(() => {
    setLockText(user.state === LOCKED ? 'Разблокировать' : 'Заблокировать');
  }, [user]);

  useEffect(() => {
    setUser(data);

    if (photo) {
      setFiles([photo]);
    }
  }, [data, photo]);

  useEffect(() => {
    async function setBlueStampImageAsSign() {
      const sign = await getBlueStampImage();

      sign && setSigns([sign]);
    }

    setBlueStampImageAsSign();
  }, [user]);

  useEffect(() => {
    if (!approversSelectItems) {
      http.api.get('/users/approvers').then(data => {
        setApproversSelectItems(
          data.map(item => ({
            key: item.accountId,
            text: `${item.lastName} ${item.firstName}`,
            value: item.accountId,
            // todo add empty image
            image: item.photoId ? { avatar: false, src: getFileURL(item.photoId) } : undefined,
          }))
        );
      });
    }
  }, [approversSelectItems]);

  const submitText = (isNew && 'Добавить') || 'Подтвердить';

  const onChangeValue = useCallback(
    (key, value, bool) => {
      setUser(Object.assign({}, user, { [key]: bool ? value : value.slice(0, MAX_LENGTH) }));
    },
    [user]
  );

  const onChangeApprovers = (event, { value }) => {
    setUser({ ...user, personalApproverIds: value });
  };

  const onChangeCorporateApprover = useCallback(
    value => {
      const updatedUser = Object.assign({}, user);
      updatedUser.corporateApprover = value;
      if (updatedUser.corporateApprover && !updatedUser.megafonEmployee) {
        updatedUser.megafonEmployee = true;
      }
      setUser(updatedUser);
    },
    [user]
  );

  const onChangeMegafonEmployee = useCallback(
    value => {
      const updatedUser = Object.assign({}, user);
      updatedUser.megafonEmployee = value;
      if (!updatedUser.megafonEmployee && updatedUser.corporateApprover) {
        updatedUser.corporateApprover = false;
      }
      setUser(updatedUser);
    },
    [user]
  );

  const save = user => {
    clearError();
    if (!checkIsAnyEmpty(user)) {
      onSubmit(user, (files.length && files[0]) || null, (signs.length && signs[0]) || null)
        .then(user => {
          setUser(user);
          setFormSuccess(true);
        })
        .catch(err => {
          setFormError((err && err.message) || 'Произошла ошибка');
        });
    }
  };

  useEffect(() => {
    const id = setTimeout(() => {
      setFormSuccess(false);
    }, MESSAGE_TIMEOUT);
    return () => clearTimeout(id);
  }, [formSuccess]);

  useEffect(() => {
    const id = setTimeout(() => {
      setFormError(false);
    }, MESSAGE_TIMEOUT);
    return () => clearTimeout(id);
  }, [formError]);

  const checkIsAnyEmpty = user => {
    const error = {};
    let isError = false;
    let isErrorEng = false;
    for (const key of REQUIRED_FIELDS) {
      if (user[key] === '') {
        error[key] = 'Обязательное поле';
        if (key.includes('en')) {
          isErrorEng = true;
        } else {
          isError = true;
        }
      }
    }
    setError(error);
    setIsError(isError);
    setIsErrorEng(isErrorEng);
    return isError || isErrorEng;
  };

  const clearError = () => {
    setError(false);
    setIsError(false);
    setIsErrorEng(false);
    setFormError(false);
    setFormSuccess(false);
  };

  const getFields = (obj, keys) => {
    return keys.reduce(
      (acc, next) =>
        acc.concat({
          key: next,
          name: obj[next],
        }),
      []
    );
  };

  const remove = () => {
    http.api.delete(`/users/${user.phone}`).then(user => {
      setUser(user);
      setFormSuccess(true);
    }).catch(err => {
      setFormError((err && err.message) || 'Произошла ошибка');
    });
  };


  const lock = () =>
    save(Object.assign({}, user, { state: user.state === LOCKED ? ACTIVE : LOCKED }));

  const reset = () => {
    setResetPin(false);

    http.api.post(`/users/reset-pin/${user.id}`).then(res => {
      setFinishResetPin(true);
    });
  };

  const loginAsUser = () => {
    http.api
      .post(`/users/loginas/${user.phone}`)
      .then(res => {
        console.info(`Login as user ${formatUserName(user)} with phone ${user.phone}`);

        window.location.replace(getWebClientUrl());
      })
      .catch(error => console.error({ error }));
  };

  const handleChangeValue = key => e => onChangeValue(key, e.target.value);

  return (
    <Segment className="users-form">
      {Object.keys(userStatus).includes(user.state) && (
        <div>
          Статус: <span style={{ color: 'red' }}>{userStatus[user.state]}</span>
        </div>
      )}
      <div className="users-form__tabs">
        <div
          className={
            'users-form__tab' + (tab === RUS ? ' --active' : '') + (isError ? ' --error' : '')
          }
          onClick={() => setTab(RUS)}>
          Рус
        </div>
        <div
          className={
            'users-form__tab' + (tab === ENG ? ' --active' : '') + (isErrorEng ? ' --error' : '')
          }
          onClick={() => setTab(ENG)}>
          Eng
        </div>
      </div>
      <Form>
        {tab === RUS && (
          <Fragment>
            <Form.Group widths="equal">
              {getFields(LANG_BLOCK_RU, ['lastName', 'firstName', 'middleName']).map(
                ({ name, key }) => (
                  <Form.Field key={key}>
                    <label>{name}</label>
                    <input value={user[key]} onChange={handleChangeValue(key)} />
                    {isError && error[key] !== '' && (
                      <div className="users-form__error">{error[key]}</div>
                    )}
                  </Form.Field>
                )
              )}
            </Form.Group>
            {isPrimaryUser && (
              <Form.Group widths="equal">
                {getFields(LANG_BLOCK_RU, ['company', 'position', 'description']).map(
                  ({ name, key }) => (
                    <Form.Field key={key}>
                      <label>{name}</label>
                      <input
                        value={user[key]}
                        onChange={handleChangeValue(key)}
                        maxLength={MAX_LENGTH}
                      />
                      {isError && error[key] !== '' && (
                        <div className="users-form__error">{error[key]}</div>
                      )}
                    </Form.Field>
                  )
                )}
              </Form.Group>
            )}
          </Fragment>
        )}
        {tab === ENG && (
          <Fragment>
            <Form.Group widths="equal">
              {getFields(LANG_BLOCK_EN, ['lastName_en', 'firstName_en', 'middleName_en']).map(
                ({ name, key }) => (
                  <Form.Field key={key}>
                    <label>{name}</label>
                    <input value={user[key]} onChange={handleChangeValue(key)} />
                    {isErrorEng && error[key] !== '' && (
                      <div className="users-form__error">{error[key]}</div>
                    )}
                  </Form.Field>
                )
              )}
            </Form.Group>
            {isPrimaryUser && (
              <Form.Group widths="equal">
                {getFields(LANG_BLOCK_EN, ['company_en', 'position_en', 'description_en']).map(
                  ({ name, key }) => (
                    <Form.Field key={key}>
                      <label>{name}</label>
                      <input
                        value={user[key]}
                        onChange={handleChangeValue(key)}
                        maxLength={MAX_LENGTH}
                      />
                      {isErrorEng && error[key] !== '' && (
                        <div className="users-form__error">{error[key]}</div>
                      )}
                    </Form.Field>
                  )
                )}
              </Form.Group>
            )}
          </Fragment>
        )}
        <Form.Group widths="3">
          {getFields(COMMON_BLOCK, ['phone', 'email']).map(({ name, key }) => (
            <Form.Field key={key}>
              <label>{name}</label>
              <input value={user[key]} onChange={handleChangeValue(key)} />
              {isError && error[key] !== '' && (
                <div className="users-form__error">{error[key]}</div>
              )}
            </Form.Field>
          ))}
        </Form.Group>

        {isPrimaryUser && (
          <Form.Group inline>
            <label>Роль</label>
            {isNew &&
              roles.map(r => (
                <Form.Radio
                  key={r}
                  label={r}
                  value={r}
                  checked={user.role === r}
                  onChange={(e, { value }) => onChangeValue('role', value)}
                />
              ))}
            {!isNew && user.role}
          </Form.Group>
        )}

        {isPrimaryUser && user.role === ROLE_APPROVER && approversSelectItems && (
          <Form.Group>
            <Form.Field className="users-form__select">
              <Dropdown
                placeholder="Выберите из списка"
                fluid
                multiple
                selection
                options={approversSelectItems}
                defaultValue={user.personalApproverIds}
                onChange={onChangeApprovers}
              />
            </Form.Field>
          </Form.Group>
        )}
        {isPrimaryUser && user.role === ROLE_INITIATOR && (
          <Form.Group grouped>
            <Form.Field
              label="Согласовывает документы от МегаФон"
              control={Checkbox}
              checked={user.corporateApprover}
              onClick={(e, { checked }) => onChangeCorporateApprover(checked)}
            />
            <div className="users-form__helperText">
              При создании пакета документов / исполнение поручений сотрудниками
              <br />
              компании МФ, этот пользователь добавится в состав согласующих
            </div>
          </Form.Group>
        )}

        {isPrimaryUser && user.role === ROLE_INITIATOR && (
          <Form.Group grouped>
            <Form.Field
              label="Является сотрудником компании МегаФон"
              control={Checkbox}
              checked={user.megafonEmployee}
              onClick={(e, { checked }) => onChangeMegafonEmployee(checked)}
            />
            <div className="users-form__helperText">
              При создании пакета документов / исполнение поручений
              <br />
              этим пользователем в состав согласующих добавится согласующий от МегаФон
            </div>
          </Form.Group>
        )}

        <Form.Group>
          <Form.Field
            label="Рассылка СМС"
            control={Checkbox}
            checked={user.sendSms}
            onClick={() => onChangeValue('sendSms', !user.sendSms, true)}
          />
        </Form.Group>
        {isPrimaryUser && (
          <Form.Group>
            <Form.Field
              label="Демо-участник"
              control={Checkbox}
              checked={user.demo}
              onClick={() => onChangeValue('demo', !user.demo, true)}
            />
          </Form.Group>
        )}

        {isPrimaryUser && (
          <Form.Group grouped>
            <label>Группа</label>
            {user.role === ROLE_INITIATOR && (
              <Form.Field
                label="Наблюдатель за всеми документами"
                control={Checkbox}
                checked={user.observer}
                onChange={() => onChangeValue('observer', !user.observer, true)}
              />
            )}
            <Form.Field
              label="Помощник утверждающего"
              control={Checkbox}
              checked={user.finalApproverAssistant}
              onChange={() =>
                onChangeValue('finalApproverAssistant', !user.finalApproverAssistant, true)
              }
            />
          </Form.Group>
        )}

        {isPrimaryUser && (
          <Form.Group>
            <Form.Field>
              <label>Подпись</label>
              <BlueStampSvg
                lastName={user.lastName}
                firstName={user.firstName}
                middleName={user.middleName}
              />
            </Form.Field>

            <canvas id="sign_canvas" width="240" height="80"></canvas>
          </Form.Group>
        )}

        {isPrimaryUser && (
          <Form.Group>
            <Accordion>
              <Accordion.Title
                active={expandSecretaries}
                index={0}
                onClick={handleExpandSecretaries}>
                <Icon name="dropdown" />
                Секретари
              </Accordion.Title>
              <Accordion.Content active={expandSecretaries}>
                {isNew ? (
                  <Message info>
                    <Icon name="add user" />
                    Добавление секретарей будет возможным после создания пользователя
                  </Message>
                ) : (
                  <Secretaries lang={tab === RUS ? 'RUS' : 'ENG'} primaryUser={user} />
                )}
              </Accordion.Content>
            </Accordion>
          </Form.Group>
        )}
        {isPrimaryUser && (
          <Form.Group>
            <Form.Field>
              <div className="users-form__photo">
                <PhotoInputPreview
                  files={files}
                  onChange={f => setFiles(f)}
                  names={['фотографию', 'фотографии', 'фотографий']}
                />
              </div>
            </Form.Field>
          </Form.Group>
        )}

        <Form.Group>
          <Button onClick={onGoBack} basic className="users-form__action">
            Назад
          </Button>
          <Button onClick={() => save(user)} color="blue" className="users-form__action">
            {submitText}
          </Button>
          <Button onClick={lock} className="users-form__action">
            {lockText}
          </Button>
          <Button onClick={remove} color="red" className="users-form__action">
            Удалить
          </Button>
          <Button onClick={() => setResetPin(true)} color="black" className="users-form__action">
            Сбросить пин
          </Button>

          {![NEW, REMOVED, LOCKED].includes(user.state) && (
            <Button onClick={loginAsUser} basic className="users-form__action">
              {`Войти как ${formatUserName(user)}`}
            </Button>
          )}
        </Form.Group>
      </Form>
      <Transition visible={formSuccess} animation="scale" duration={500}>
        <Message attached="bottom" success>
          <Icon name="check" />
          Изменения сохранены
        </Message>
      </Transition>
      <Transition visible={Boolean(formError)} animation="scale" duration={500}>
        <Message attached="bottom" error>
          <Icon name="times" />
          {formError}
        </Message>
      </Transition>

      <Modal size="tiny" open={resetPin} onClose={() => setResetPin(false)}>
        <Modal.Header>Сброс пин-кода</Modal.Header>
        <Modal.Content>
          <p>Вы уверены, что хотите сбросить пин?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative content="Нет" onClick={() => setResetPin(false)} />
          <Button positive content="Да" onClick={reset} />
        </Modal.Actions>
      </Modal>
      <Modal size="tiny" open={finishResetPin} onClose={() => setFinishResetPin(false)}>
        <Modal.Header>Сброс пин-кода</Modal.Header>
        <Modal.Content>
          <p>Сброс пин-кода произведён.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button positive content="OK" onClick={() => setFinishResetPin(false)} />
        </Modal.Actions>
      </Modal>
    </Segment>
  );
}

UsersForm.defaultProps = {
  data: defaultUser,
  photo: null,
  sign: null,
  isNew: false,
};

UsersForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onGoBack: PropTypes.func.isRequired,
  data: PropTypes.object,
  photo: PropTypes.any,
  sign: PropTypes.any,
  isNew: PropTypes.bool,
};

export default UsersForm;
