import {
  UNDEFINED,
  /** На согласовании*/
  REQUESTED,

  /** Согласовано */
  APPROVED,

  /** Отлколено */
  REJECTED,

  /** Рассмотрено */
  EXECUTED,

  /** Ожидает моего решения **/
  MY_REQUESTED,

  /** Ожидает моего ответа **/
  MY_RESPONSE,

  /** На утверждении **/
  FINAL_REQUESTED,

  /** Утверждено **/
  FINAL_APPROVED,

  /** Не утверждено **/
  FINAL_REJECTED,

  /** На доработке **/
  REWORKING,

  /** В работе **/
  EXECUTING,

  /** Вернул на доработку **/
  RETURNED_FOR_REWORKING,

  /** Ответ подготовлен **/
  RESPONSE_PREPARED,

  /* Пропущен при согласовании (если утверждающий утвердил пакет/поручение, а пользователь был в нем согласующим и еще не дал ответа) */
  SKIPPED,

  /** Ответ не получен */
  EXPIRED,
} from './decision_business_statuses';

const statusesMap = {
  [UNDEFINED]: '',
  [REQUESTED]: 'На согласовании',
  [APPROVED]: 'Согласовано',
  [REJECTED]: 'Отклонено',
  [EXECUTED]: 'Рассмотрено',
  [MY_REQUESTED]: 'Ожидает моего решения',
  [MY_RESPONSE]: 'Ожидает моего ответа',
  [FINAL_REQUESTED]: 'На утверждении',
  [FINAL_APPROVED]: 'Утверждено',
  [FINAL_REJECTED]: 'Не утверждено',
  [REWORKING]: 'На доработке',
  [EXECUTING]: 'В работе',
  [RETURNED_FOR_REWORKING]: 'Вернул на доработку',
  [RESPONSE_PREPARED]: 'Ответ подготовлен',
  [SKIPPED]: 'Пропущен',
  [EXPIRED]: 'Ответ не получен',
};

export default statusesMap;
