import React from 'react';
import './attach.scss';
import http from '../../../rest';
import PropTypes from 'prop-types';

function Attach({ onUpload }) {
  const onChange = e => {
    const calls = [];
    for (const file of e.target.files) {
      const formData = new FormData();
      formData.append('name', file.name);
      formData.append('type', file.name.split('.').pop());
      formData.append('file', file);
      calls.push(http.api.post('/document/upload', formData));
    }
    if (calls.length) {
      Promise.all(calls)
        .then(results => {
          onUpload(results.map(({ data }) => data));
        })
        .catch(console.log);
    }
  };
  return (
    <label className="attach">
      <input type="file" multiple onChange={onChange} />
    </label>
  );
}
Attach.propTypes = {
  onUpload: PropTypes.func.isRequired,
};

export default Attach;
