import { ROLE_INITIATOR } from '../../../constants/roles';

export const defaultUser = {
  lastName: '',
  firstName: '',
  middleName: '',
  company: '',
  position: '',
  description: '',
  phone: '',
  email: '',
  role: ROLE_INITIATOR,
  lastName_en: '',
  firstName_en: '',
  middleName_en: '',
  company_en: '',
  position_en: '',
  description_en: '',
  state: 'NEW',
  sendSms: false,
  demo: false,
  personalApproverIds: [],
};

export const LANG_BLOCK_RU = {
  lastName: 'Фамилия',
  firstName: 'Имя',
  middleName: 'Отчество',
  company: 'Компания',
  position: 'Должность',
  description: 'Описание',
};
export const LANG_BLOCK_EN = {
  lastName_en: 'Last Name',
  firstName_en: 'First Name',
  middleName_en: 'Middle Name',
  company_en: 'Company',
  position_en: 'Position',
  description_en: 'Description',
};

export const COMMON_BLOCK = {
  email: 'Почта',
  phone: 'Телефон',
};
