export const getExtension = (originalFile = '') => {
  const parts = originalFile.split('.');
  const extension = parts[parts.length - 1];
  switch (extension) {
    case 'doc':
    case 'docx':
    case 'rtf':
      return 'word';
    case 'xls':
    case 'xlsx':
      return 'excel';
    case 'pdf':
      return 'pdf';
    case 'jpeg':
    case 'jpg':
      return 'jpg';
    case 'gif':
      return 'gif';
    case 'png':
      return 'png';
    default:
      return 'doc';
  }
};
