import React from 'react';

import { ROLE_INITIATOR, ROLE_FINAL_APPROVER } from '../../../constants/roles.js'; //  ROLE_APPROVER, ROLE_FINAL_APPROVER,

import {
  REQUESTED,
  FINAL_REQUESTED,
  EXECUTING,
  MY_REQUESTED,
  MY_RESPONSE,
} from '../../../constants/decision_business_statuses';

import formatDate from '../../../utils/date_utils';

export const DateTimeByStatus = ({ decision }) => {
  const { user, lastModifiedDate, businessStatus, completedDate } = decision;

  const lastDate =
    [ROLE_INITIATOR, ROLE_FINAL_APPROVER].includes(user.role) && completedDate
      ? completedDate
      : lastModifiedDate;

  return businessStatus &&
    ![REQUESTED, EXECUTING, FINAL_REQUESTED, MY_REQUESTED, MY_RESPONSE].includes(businessStatus) ? (
    <div>{formatDate(lastDate, true)}</div>
  ) : null;
};
