import * as types from './action_types';

const initialState = {
  authenticated: undefined,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER_AUTH:
      return { ...state, authenticated: action.payload };
    case types.AUTH_USER:
      return { ...state, authenticated: true, ...action.payload };
    case types.LOGOUT_USER:
      return initialState;
    default:
      return state;
  }
}
